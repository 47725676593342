/* #region ********************** GERAL ********************** */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
}
img {
  max-width: 100%;
  height: auto;
}
.App {
  min-height: 100vh;
  width: 100%;
  margin: 0rem auto;
}
body {
  max-width: 100%;
  overflow-x: hidden;
  background-color: #031663de;
}
/* #endregion */

/* #region ********************** NAVBAR ********************** */
nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 90px;
  margin: 0 4rem;
}
.navbar-menu-container {
  display: none;
}
.navbar-links-container a {
  margin-right: 3rem;
  text-decoration: none;
  color: rgb(243, 240, 240);
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
}
.navbar-link:hover {
  color: yellow;
}
.primary-button {
  padding: 0.9rem 1.75rem;
  background-color: yellow;
  opacity: 80%;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  transition: transform 0.3s;
}
.primary-button:hover {
  background-color: yellow;
  opacity: 1;
  color: black;
}
.nav-logo-container {
  width: 85px;
  padding-top: 2px;
  transition: transform 0.3s;
  opacity: 95%;
}
.nav-logo-container:hover {
  transform: scale(1.05);
  opacity: 100%;
}
/* NavBar Menu Container */
.navbar-menu-container {
  display: none;
  color: yellow;
  font-size: large;
  scale: 2;
}
.navbar-menu-container-box {
  width: 250px;
  height: 100%;
  color: white;
  background-color: #061c71;
}
.navbar-menu-container-list-text {
  color: white;
  text-decoration: none;
}
.navbar-menu-container-list-icon {
  color: white !important;
}
.navbar-menu-container-list-icon {
  scale: 0.8;
  color: rgba(255, 255, 255, 0.2) !important;
}
.navbar-menu-container-list {
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.02);
}
.navbar-menu-container-list-text:hover {
  color: yellow;
}
.navbar-menu-container-list:hover {
  color: yellow;
  cursor: pointer;
  background-color: #031663; 
}

/* #endregion */

/* #region ********************** HOME ********************** */
.home-banner-container {
  position: relative;
  display: flex;
  padding: 3rem 4rem;
  min-height: 90vh;
  border-top: 0.1rem solid rgba(255, 255, 255, 0.15);
  align-items: center;
  justify-content: space-between;
  background-color: #031663c4;
}
.home-bannerImage-container {
  position: absolute;
  top: -15rem;
  right: 0;
  z-index: -2;
  max-width: 50rem;
  max-height: 100%;
  opacity: 35%;
}
.home-image-section {
  max-width: 600px;
  flex: 1;
  padding-top: 40px;
  transition: transform 0.3s;
  opacity: 0.98;
  /* background-color: pink; */
}
.home-image-section:hover {
  transform: scale(1.1);
  opacity: 1;
}
.home-text-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* background-color: #719ECE; */
}
.primary-heading {
  font-size: clamp(2rem, 5vw, 4rem);
  color: white;
  max-width: 600px;
}
.primary-text {
  font-size: clamp(1rem, 3vw, 1.5rem);
  max-width: 500px;
  color: rgb(216, 214, 214);
  margin: 1.5rem 0rem;
}
.secondary-button {
  padding: 1rem 2.5rem;
  background-color: #2045d9de;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: white;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.secondary-button svg {
  margin-left: 0.75rem;
  font-size: 1.5rem;
}
.secondary-button:hover {
  background-color: yellow;
  color: black;
}
/* #endregion */

/* #region ********************** SIMULATOR ********************** */
.simulator-section-container {
  margin-top: 0rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3rem 4rem;
  min-height: 90vh;
  border-top: 0.1rem solid rgba(255, 255, 255, 0.06);
  /* background-color: #03166385; */
}
.simulator-section-container a {
  text-decoration: none;
}
.simulator-background-image-container {
  position: absolute;
  left: 17rem;
  right: auto;
  z-index: -2;
  opacity: 20%;
  padding-top: 2dvh;
  width: 35%;
}
.simulator-section-text-container,
.simulator-section-text-container-two {
  flex: 1;
  justify-content: center;
  align-items: flex-end;
  display: flex;
  position: relative;
  flex-direction: column;
  right: 0;
  left: auto;
  max-width: 100%;
}
.simulator-section-text-container-two {
  align-items: flex-start !important;
}
.simulator-buttons-container {
  margin-top: 2rem;
  display: flex;
}
.simulator-section-image-container {
  flex: 1;
  transition: transform 0.3s;
  opacity: 0.98;
  margin-right: 3rem;
}
.simulator-section-image-container:hover {
  transform: scale(1.1);
  opacity: 1;
}
.primary-subheading {
  font-weight: 700;
  color: rgb(182, 182, 248);
  font-size: 1.15rem;
  padding-bottom: 1rem;
}
/* #endregion */

/* #region ********************** ABOUT ********************** */
.about-section-container {
  margin-top: 0rem;
  position: relative;
  display: flex;
  padding: 3rem 4rem;
  min-height: 90vh;
  border-top: 0.1rem solid rgba(255, 255, 255, 0.15);
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
}
.about-section-image-container {
  flex: 1;
  right: 0;
  left: auto;
  transition: transform 0.3s;
  opacity: 0.98;
  max-width: 100%;
}
.about-section-image-container:hover {
  transform: scale(1.1);
  opacity: 1;
}
.about-section-text-container {
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
}
.about-section-text-container-left {
  padding-right: 100px;
  padding-left: 0px;
  max-width: 100%;
}
.primary-subheading {
  font-weight: 700;
  color: rgb(182, 182, 248);
  font-size: 1.15rem;
}
.about-buttons-container {
  margin-top: 2rem;
  display: flex;
}
.about-bannerImage-container {
  position: absolute;
  z-index: -2;
  width: 40%;
  opacity: 0.15;
  right: 4rem;
  left: auto;
  top: 20px;
}
/* #endregion */

/* #region ********************** PRODUCT ********************** */
.product-section-wrapper {
  margin-top: 0rem;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3rem 4rem;
  min-height: 90vh;
  border-top: 0.1rem solid rgba(255, 255, 255, 0.06);
  background-color: #03166385;
}
.product-carousel {
  display: flex;
  position: flex;
  padding: 3rem 4rem;
  align-items: center;
  width: 100%;
}
.product-carousel-two {
  justify-content: center;
  width: 100%;
}
.product-carousel .carousel-item {
  flex: 1;
}
.carousel-controls {
  display: flex;
  position: flex;
  justify-content: space-between;
  align-items: center;
}
.carousel-left {
  left: 0 !important;
  justify-content: flex-start !important;
  right: auto !important;;
}
.carousel-right {
  right: 0 !important;
  left: auto;
  justify-content: flex-end !important;
  padding-left: 0.5rem;
}
.carousel-controls button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: rgba(255, 255, 255);
  opacity: 50%;
  border-radius: 50%;
  text-align: center;
  transition: transform 0.3s, opacity 0.3s;
  transform: scale(2.5);
}
.carousel-controls button:hover {
  opacity: 0.8;
  transform: scale(2.8);
  color: white;
}
.product-section-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
}
.product-section-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.product-section-top p {
  text-align: center;
  max-width: 600px !important;
}
.product-section-top h1 {
  max-width: 700px !important;
}
.product-section-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  color: white;
  background-color: #031663de;
  padding: 2rem 2rem;
  border-radius: 1rem;
  margin: 1rem 2rem;
  max-height: 400px;
  min-height: 400px;
  max-width: calc(100% / 3);
  align-items: center;
  justify-content: center;
  font-weight: 500;
  text-align: center;
  font-size: clamp(1rem, 1vw, 4rem);
  transform: scale(1.0);
  transition: transform 0.3s;
  opacity: 0.98;
}
.product-section-info:hover {
  transform: scale(1.05);
  opacity: 1;
  background-color: yellow;
  color: black;
}
.product-section-info img {
  align-items: center;
  justify-content: center;
  font-weight: 600;
  max-height: 150px;
  margin-bottom: 1rem;
}
/* #endregion */

/* #region ********************** CONTACT ********************** */
.contact-page-wrapper h1 {
  max-width: 900px;
  font-size: clamp(2rem, 4.5vw, 3.5rem);
}
.contact-page-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 3rem 1.5rem;
  background-color: #031663c4;
}
.contact-form-container-two {
  margin-top: 3rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.contact-form-container,
.contact-form-container-textarea  {
  max-width: 40rem;
  width: 100%;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  padding: 0.1rem;
  border-radius: 1rem;
  background-color: rgb(249, 244, 244);
}
.contact-form-container-textarea {
  margin-bottom: 2rem;
}
.contact-form-container-textarea textarea {
  border-radius: 1rem;
}
.contact-form-container input {
  border-radius: 0.5rem;
}
.contact-form-container-textarea textarea,
.contact-form-container input {
  width: 100% !important;
  font-size: 1rem;
  border: none;
  resize: none;
  margin: 0.2rem;
  padding: 1rem;
  background-color: rgb(249, 244, 244);
}
.contact-form-container-textarea textarea:focus,
.contact-form-container input:focus {
  outline: none !important;
  border-color: #0778f0;
  box-shadow: 0 0 8px #0778f0;
  border-radius: 1rem;
}
.contact-form-container-textarea textarea:focus {
  border-radius: 1.5rem;
}
.contact-form-container button {
  background-color: #f0db1c;
  border: none;
  padding: 0.5rem 2rem;
  border-radius: 5rem;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.2s;
}
.contact-form-container button:hover {
  background-color: #d7c118;
}
/* #endregion */

/* #region ********************** FOOTER ********************** */
.footer-section {
  margin: 3rem 0rem;
  display: flex;
  flex: 1;
}
.footer-section-columns {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.footer-line {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin: 10px 20px;
  opacity: 0.5;
}
.footer-section-columns span {
  padding-left: 30px;
  margin: 0.25rem 0rem;
  font-weight: 500;
  color: rgba(245, 245, 245, 0.8);
  cursor: pointer;
  font-size: medium;
}
.footer-section-title {
  font-weight: 600 !important;
  color: white !important;
  font-size: large !important;
}
/* #endregion */

/* #region ********************** WHATSAPP ********************** */
.whatsapp-container {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
}
.vendas-whatsapp {
  position: relative;
  z-index: 1;
}
.suporte-whatsapp {
  position: relative;
  z-index: 2;
}
/* #endregion */

/* #region ********************** BACKTOTOP ********************** */
#backToTopBtn {
  display: none;
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 99;
  font-size: 2px;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background-color: rgba(128, 128, 128, 0.5);
  color: white;
  cursor: pointer;
  padding: 0px;
  border-bottom-left-radius: 0.5rem;
  opacity: 0.8;
}
#backToTopBtn:hover {
  opacity: 1;
}
/* #endregion */

@media (min-width: 901px) and (max-width: 1200px) {
  .navbar-links-container a {
    margin-right: 1rem;
    font-size: 0.9rem;
    padding-left: 0.5rem;
  }
  .nav-logo-container {
    width: 75px;
  }
  .home-bannerImage-container {
    max-width: 40rem;
  }
  .primary-button {
    font-size: 0.8rem;
  }
  .primary-heading {
    font-size: clamp(2rem, 3.5vw, 4rem);
  }
  .primary-text {
    font-size: clamp(1rem, 2vw, 1.5rem);
  }
  .secondary-button {
    font-size: 1rem;
  }
  .home-image-section {
    max-width: 350px;
    margin-left: 2rem;
  }
  .product-section-info {
    max-width: calc(100% / 2);
  }
  .about-section-image-container {
    padding-left: 3rem;
  }
  .footer-section-columns span {
    font-size: small;
  }
  .footer-section-title {
    font-size: medium !important;
  }
}

@media (max-width: 900px) {
  .navbar-menu-container {
    display: flex;
  }
  .nav-logo-container {
    max-width: 60px;
  }
  .navbar-links-container {
    display: none;
  }
  .home-bannerImage-container {
    max-width: 900px;
  }
  .home-banner-container,
  .about-section-container {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  .simulator-section-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .simulator-section-text-container {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .simulator-section-text-container-two {
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
  }
  .about-section-container {
    flex-direction: column;
  }
  .home-image-section {
    width: 100%;
    max-width: 400px;
  }
  .primary-heading {
    text-align: center;
    max-width: 90%;
  }
  .primary-text {
    text-align: center;
    max-width: 80%;
  }
  .home-text-section,
  .about-section-text-container{
    justify-content: center;
    align-items: center;
    /* margin-top: 4rem; */
  }
  .secondary-button {
    font-size: 1rem;
    padding: 0.8rem 2rem;
    cursor: pointer;
  }
  .product-section-info {
    max-width: 100%
  }
  .about-buttons-container,
  .simulator-buttons-container {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .primary-subheading {
    text-align: center;
  }
  .about-section-image-container,
  .simulator-section-image-container {
    margin-top: 4rem;
    margin-bottom: 3rem;
    width: 100%;
    max-width: 500px;
    margin-right: 0;
  }
  .contact-form-container,
  .contact-form-container-textarea  {
    padding: 0.5rem !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem !important;
    max-width: 33rem;
  }
  .contact-form-container input,
  .contact-form-container-textarea textarea {
    font-size: 1rem !important;
    max-width: 100%;
    padding: 0.5rem 1rem !important;
    margin-bottom: 0.8rem !important;
    text-align: center;
  }
  .carousel-controls button {
    transform: scale(1.5);
  }
  .carousel-controls button:hover {
    transform: scale(1.8);
  }
  .footer-section {
    flex-direction: column;
  }
  .footer-section-columns {
    margin-bottom: 2rem;
    align-items: center;
  }
  .footer-section-columns span {
    padding: 0;
  }
}

@media (max-width: 600px) {
  .home-bannerImage-container,
  .about-background-image-container {
    display: none;
  }
  .contact-form-container,
  .contact-form-container-textarea  {
    max-width: 20rem !important;
  }
  .contact-form-container input,
  .contact-form-container-textarea textarea {
    font-size: 0.8rem !important;
  }
  .product-section-wrapper {
    padding: 3rem 1rem;
  }
  .product-carousel {
    padding: 0;
  }
  .product-section-bottom {
    padding: 0;
    min-width: 100%; 
  }
  .product-section-info {
    margin: 1rem 0;
  }
  .carousel-controls button {
    transform: scale(1.2);
  }
  .carousel-controls button:hover {
    transform: scale(1.5);
  }
  .simulator-section-image-container {
    margin-top: 3rem;
    margin-bottom: 3rem;
    width: 100%;
    max-width: 300px;
  }
  .home-image-section {
    max-width: 300px;
    margin-bottom: 1rem;
  }
  .simulator-background-image-container {
    display: none;
  }
  .home-banner-container,
  .about-section-container,
  .simulator-section-container,
  .footer-section {
    padding: 3rem 1rem;
  }
  .product-text-title {
    font-size: 14px;
    font-weight: 500;
  }
  .product-text-info {
    font-size: 12px;
  }
  .primary-heading {
    font-size: 24px !important;
    padding-top: 1rem;
  }
  .secondary-button {
    font-weight: 500;
    font-size: 14px;
  }
  .primary-text {
    font-size: 14px;
  }
  .product-section-info h3 {
    font-weight: 600;
    font-size: 14px;
  }
  .product-section-info span {
    font-size: 14px;
  }
  .contact-form-container-two {
    margin-top: 3rem;
  }
  .contact-form-container-textarea,
  .contact-form-container {
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    margin-top: 0.5rem;
    background-color: transparent;
  }
  .contact-form-container-textarea textarea,
  .contact-form-container input {
    margin: 0;
    padding: 0.5rem 0.1rem !important;
  }
  .footer-section-columns span {
    font-size: small;
  }
  .footer-section-title {
    font-size: medium !important;
  }
}